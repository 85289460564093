@if (label) {
  <div class="label">
    {{ label }}
  </div>
}
@if (icon) {
  <app-icon [shape]="icon"></app-icon>
}
@if (showMenu) {
  <div #menuContent class="menu-content" [ngClass]="contentClass">
    <div class="menu-canvas" [ngClass]="{ menu: hasMenuItems }">
      <ng-content></ng-content>
    </div>
  </div>
}
