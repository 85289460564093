import {
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { MenuItemComponent } from '../menu-item/menu-item.component';
import { NgClass } from '@angular/common';
import { IconComponent } from '../icon/icon.component';

enum State {
  hidden = 0,
  fadingIn = 1,
  visible = 2,
  fadingOut = 3,
}

const MENU_DELAY = 170;
const TRIANGLE_SIZE = 5;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  standalone: true,
  imports: [NgClass, IconComponent],
})
export class MenuComponent implements OnDestroy {
  @Output() showMenuEvent = new EventEmitter<void>();
  @Output() hideMenuEvent = new EventEmitter<void>();

  // ul um ur, dl dm dr
  @Input() direction = 'dm';
  @Input() icon = 'pi pi-ellipsis-h';
  @Input() label = '';
  @Input() openOnMouseover = false;
  @Input() hideIfClickedOutside = true;
  @Input() menuZIndex = '200';
  @Input() disabled?: string;
  @Input() offset: string | number = 0;

  @HostBinding('class.expanded')
  showMenu = false;
  contentClass = '';

  get hasMenuItems(): boolean {
    return (this.submenus?.length ?? 0) > 0 || (this.menuItems?.length ?? 0) > 0;
  }

  @HostBinding('class.submenu')
  get isSubmenu(): boolean {
    return !!this.parentMenu;
  }

  @ViewChild('menuContent')
  private menuContent?: ElementRef<HTMLDivElement>;

  @ViewChild('menuIcon')
  private menuIcon?: ElementRef<HTMLDivElement>;

  @ContentChildren(forwardRef(() => MenuComponent))
  private submenus?: QueryList<MenuComponent>;

  @ContentChildren(MenuItemComponent)
  private menuItems?: QueryList<MenuItemComponent>;

  // helper variable to prevent multiple quick events like wheel-events from repeatedly starting the fade-out animation
  private state: State = State.hidden;

  private parentMenu?: MenuComponent;

  constructor(private element: ElementRef<HTMLElement>) {}

  @HostListener('document:mousedown', ['$event'])
  onDocumentClicked(event: MouseEvent) {
    this.closeIfNeeded(event);
  }

  @HostListener('document:wheel', ['$event'])
  onDocumentWheel(event: WheelEvent) {
    this.closeIfNeeded(event);
  }

  @HostListener('document:touchmove', ['$event'])
  onDocumentTouchMove(event: TouchEvent) {
    this.closeIfNeeded(event);
  }

  @HostListener('mousedown')
  onMouseDown() {
    return this.toggleVisibility();
  }

  @HostListener('touchmove')
  onTouchMove() {
    return this.toggleVisibility();
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    if (this.openOnMouseover) {
      this.show();
    }

    return false;
  }

  ngOnDestroy() {
    // If selecting a menu item has the consequence that the app-menu node is removed
    // for example by an ngIf, the expanded menu canvas stays behind and has to be
    // cleaned up here.
    if (this.showMenu) {
      this.menuContent?.nativeElement.remove();
    }
  }

  hideAllSubmenus() {
    this.submenus?.forEach((x) => {
      x.hideAllSubmenus();
      x.hide();
    });
  }

  hideAll() {
    if (this.parentMenu) {
      let rootMenu = this.parentMenu;
      while (rootMenu.parentMenu) {
        rootMenu = rootMenu.parentMenu;
      }
      rootMenu.hideAllSubmenus();
      rootMenu.hide();
    } else {
      this.hide();
    }
  }

  hide() {
    if (this.state === State.visible) {
      this.state = State.fadingOut;
      const menubox = this.menuContent?.nativeElement;
      if (typeof menubox !== 'undefined') {
        menubox.style.animation = `fade-out ${MENU_DELAY}ms linear 0s 1 normal forwards`;
      }
      if (this.parentMenu) {
        this.parentMenu.hideIfClickedOutside = true;
      }
      this.hideMenuEvent.emit();
      setTimeout(() => {
        this.showMenu = false;
        this.state = State.hidden;
      }, MENU_DELAY);
    }
  }

  private closeIfNeeded(event: MouseEvent | WheelEvent | TouchEvent) {
    const nativeElem = this.menuContent?.nativeElement;
    if (nativeElem && !nativeElem.contains(event.target as HTMLElement)) {
      this.onClickedOutside(event);
    }
  }

  private onClickedOutside(event: MouseEvent | WheelEvent | TouchEvent) {
    if (this.hideIfClickedOutside) {
      if (event.type === 'blur') {
        return;
      }
      let x = -9999;
      let y = -9999;
      if (event instanceof MouseEvent) {
        x = event.clientX;
        y = event.clientY;
      }
      this.hideAllParentsIfMissed(x, y);
      this.hide();
    }
  }

  private hideAllParentsIfMissed(x: number, y: number) {
    let current = this.parentMenu;
    while (current) {
      const rect = current.menuContent?.nativeElement.getBoundingClientRect();
      if (typeof rect !== 'undefined') {
        if (x <= rect.right && x >= rect.left && y >= rect.top && y <= rect.bottom) {
          return;
        }
      }
      current.hide();
      current = current.parentMenu;
    }
  }

  private toggleVisibility() {
    if (this.state === State.hidden) {
      this.show();
    } else if (this.state === State.visible) {
      this.hide();
    }
    return false; // prevent browser's default action
  }

  private show() {
    if (this.disabled) {
      return;
    }
    if (this.state !== State.hidden) {
      return;
    }
    if (this.parentMenu) {
      this.parentMenu.hideAllSubmenus();
      this.parentMenu.hideIfClickedOutside = false;
    }
    this.submenus?.forEach((x) => {
      x.parentMenu = this;
      x.menuZIndex = this.menuZIndex + 10;
      x.icon = 'pi pi-chevron-right';
    });
    const hasSubmenus = this.submenus ? this.submenus.length > 0 : false;
    const hasIcons = this.menuItems?.some((x) => !!x.icon || !!x.iconSrc);
    this.menuItems?.forEach((x) => {
      if (hasIcons && !x.icon && !x.iconSrc) {
        x.icon = 'blank';
      }
      if (hasSubmenus) {
        x.withSubmenus = true;
      }
      x.parentMenu = this;
    });
    this.showMenu = true;
    this.state = State.fadingIn;
    setTimeout(() => {
      // move the drop-down box to top of DOM tree so it can be rendered on top of everything
      const appRoot = window.document.body.firstElementChild;
      if (!appRoot) {
        throw Error('No appRoot found on document body');
      }
      if (this.menuContent) {
        appRoot.insertBefore(this.menuContent.nativeElement, appRoot.firstChild);
      }
      // position menu relative to button
      if (this.isSubmenu) {
        this.positionMenuBoxAsSubmenu();
      } else {
        this.positionMenuBoxByDirection();
      }
      const menubox = this.menuContent?.nativeElement;
      if (typeof menubox !== 'undefined') {
        menubox.style.animation = `fade-in ${MENU_DELAY}ms linear 0s 1 normal forwards`;
        menubox.style.zIndex = this.menuZIndex;
        menubox.style.visibility = 'visible';
      }
      this.showMenuEvent.emit();
      this.state = State.visible;
    }, 0);
  }

  private positionMenuBoxByDirection() {
    // position menu relative to button
    const menubox = this.menuContent?.nativeElement;
    const anchor = this.menuIcon?.nativeElement || this.element.nativeElement;
    const buttonRect = anchor.getBoundingClientRect();
    const dir = this.direction.split('');
    const numOffset = typeof this.offset === 'string' ? parseInt(this.offset, 10) : this.offset;
    let top: number;
    if (typeof menubox !== 'undefined') {
      switch (dir[0]) {
        case 'u':
          // put menu above button
          top = buttonRect.top - menubox.clientHeight - TRIANGLE_SIZE - numOffset;
          if (top >= 0) {
            menubox.style.top = top + 'px';
          } else {
            // if menu does not fit above button, but it below
            menubox.style.top = buttonRect.top + buttonRect.height + TRIANGLE_SIZE + numOffset + 'px';
            dir[0] = 'd';
          }
          break;
        case 'd':
          // put menu below button
          top = buttonRect.top + buttonRect.height + TRIANGLE_SIZE + numOffset;
          if (top + menubox.clientHeight < window.innerHeight) {
            menubox.style.top = top + 'px';
          } else {
            // if menu does not fit below button, put it above
            menubox.style.top = buttonRect.top - menubox.clientHeight - TRIANGLE_SIZE - numOffset + 'px';
            dir[0] = 'u';
          }
          break;
        default:
          throw new Error('bad direction ' + this.direction);
      }

      const leftStart = buttonRect.left + buttonRect.width / 2 + 17 - menubox.clientWidth;
      const middleStart = buttonRect.left + (buttonRect.width - menubox.clientWidth) / 2;
      const middleEnd = middleStart + menubox.clientWidth;
      const rightStart = buttonRect.left + buttonRect.width / 2 - 17;
      const rightEnd = rightStart + menubox.clientWidth;
      switch (dir[1]) {
        case 'l':
          // put menu left of button
          if (leftStart >= 0) {
            menubox.style.left = leftStart + 'px';
          } else if (middleStart >= 0) {
            // no space -> but middle position would work
            menubox.style.left = middleStart + 'px';
            dir[1] = 'm';
          } else {
            // fall-back to right position
            menubox.style.left = rightStart + 'px';
            dir[1] = 'r';
          }
          break;
        case 'm':
          // center menu relative to button
          if (middleStart >= 0) {
            if (middleEnd < window.innerWidth) {
              menubox.style.left = middleStart + 'px';
            } else {
              // not enough space to the right -> fall back to left
              menubox.style.left = leftStart + 'px';
              dir[1] = 'l';
            }
          } else {
            // not enough space to the left -> fall back to right
            menubox.style.left = rightStart + 'px';
            dir[1] = 'r';
          }
          break;
        case 'r':
          // put menu right of button
          if (rightEnd < window.innerWidth) {
            menubox.style.left = rightStart + 'px';
          } else if (middleEnd < window.innerWidth) {
            // not enough space, but middle position would work
            menubox.style.left = middleStart + 'px';
            dir[1] = 'm';
          } else {
            // fall-back to left position
            menubox.style.left = leftStart + 'px';
            dir[1] = 'l';
          }
          break;
        default:
          throw new Error('bad direction ' + this.direction);
      }
    }
    this.contentClass = 'triangle-' + dir.join('');
  }

  private positionMenuBoxAsSubmenu() {
    // position menu right next to item and fall back to left
    const menubox = this.menuContent?.nativeElement;
    const buttonRect = this.element.nativeElement.getBoundingClientRect();
    if (typeof menubox !== 'undefined') {
      menubox.style.top = buttonRect.top + 5 + 'px';
      const leftStart = buttonRect.left - menubox.clientWidth - 2;
      const rightStart = buttonRect.left + buttonRect.width + 2;
      const rightEnd = rightStart + menubox.clientWidth;
      if (rightEnd < window.innerWidth) {
        menubox.style.left = rightStart + 'px';
      } else {
        menubox.style.left = leftStart + 'px';
      }
    }
    this.contentClass = 'triangle-off';
  }
}
