import { NgClass } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IconComponent, MenuComponent } from '@art-repo/shared/components';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  standalone: true,
  imports: [NgClass, IconComponent],
})
export class MenuItemComponent implements OnInit, OnChanges {
  @Input() label = '';
  @Input() icon?: string;
  @Input() iconSrc = '';
  @Input() iconName?: string;
  @Input() checked = false;
  @HostBinding('class.disabled')
  @Input()
  disabled = false;
  @Output() selected = new EventEmitter();

  safeIconSrc?: SafeHtml;
  parentMenu?: MenuComponent;
  // If this menu-item is on the same level as submenus, reserve some extra
  // space at the end to make sure the chevron symbol is shown last even if
  // this item has the longest label.
  withSubmenus = false;

  shape?: string | SafeHtml;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.updateIcon();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['icon'] || changes['iconName'] || changes['iconSrc']) {
      this.updateIcon();
    }
  }

  @HostListener('click', [])
  onClick() {
    if (this.disabled) {
      return;
    }
    if (this.parentMenu) {
      this.parentMenu.hideAll();
    }
    this.selected.emit();
  }

  private updateIcon() {
    this.shape = this.icon ?? this.iconName;
    if (this.iconSrc) {
      // [innerHtml] filters out SVGs. To make it work it has to be sanitized explicitly
      this.shape = this.sanitizer.bypassSecurityTrustHtml(this.iconSrc);
    }
  }
}
