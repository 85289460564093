import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { InternationalizationService, LangCodes } from '@art-repo/shared/services';
import { ArtiEnvironment, ENVIRONMENT } from '@environments/model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { MenuItemComponent } from '../menu-item/menu-item.component';
import { MenuComponent } from '../menu/menu.component';

@Component({
  selector: 'app-sick-header',
  templateUrl: './sick-header.component.html',
  styleUrls: ['./sick-header.component.scss'],
  standalone: true,
  imports: [MenuComponent, MenuItemComponent, TranslateModule],
})
export class SickHeaderComponent {
  public readonly languages = this.internationalizationService.languages;
  public copyrightYear = new Date().getFullYear();

  @Input() public applicationTitle = '';

  @ViewChild('appMenu')
  appMenu: ElementRef | undefined;

  @ViewChild('userMenu')
  userMenu: ElementRef | undefined;

  language: string;

  public readonly apps = [
    {
      label: 'Webshop',
      url: 'https://www.sick.com',
      icon: 'assets/images/apps/sick-logo-small.svg',
    },
    {
      label: 'Digital Service Catalog',
      url: 'https://cloud.sick.com',
      icon: 'assets/images/apps/sick-logo-small.svg',
    },
    {
      label: 'Support Portal',
      url: 'https://support.sick.com',
      icon: 'assets/images/apps/SupportPortal.svg',
    },
    {
      label: 'AppPool',
      url: 'https://apppool.cloud.sick.com',
      icon: 'assets/images/apps/AppPool.png',
    },
    {
      label: 'AssetHub',
      url: 'https://assethub.cloud.sick.com',
      icon: 'assets/images/apps/AssetHub.png',
    },
    {
      label: 'dStudio',
      url: 'https://dstudio.cloud.sick.com',
      icon: 'assets/images/apps/dStudio.png',
    },
    {
      label: 'Field Analytics',
      url: 'https://fa.analytics.cloud.sick.com',
      icon: 'assets/images/apps/FieldAnalytics.jpg',
    },
    {
      label: 'Package Analytics',
      url: 'https://package.analytics.cloud.sick.com',
      icon: 'assets/images/apps/PackageAnalytics.png',
    },
  ];

  public readonly legalLinks = [
    {
      label: this.translateService.instant('footer.imprint'),
      url: 'https://www.sick.com/imprint',
    },
    {
      label: this.translateService.instant('footer.terms-and-conditions'),
      url: 'https://www.sick.com/tac',
    },
    {
      label: this.translateService.instant('footer.terms-of-use'),
      url: 'https://www.sick.com/terms-of-use',
    },
    {
      label: this.translateService.instant('footer.data-protection'),
      url: 'https://www.sick.com/dataprotection',
    },
    {
      label: '© ' + this.copyrightYear + ' SICK AG',
      disabled: true,
    },
  ];

  constructor(
    private auth: OAuthService,
    private internationalizationService: InternationalizationService,
    private translateService: TranslateService,
    @Inject(ENVIRONMENT) private environment: ArtiEnvironment,
  ) {
    this.language = this.translateService.currentLang;
  }

  onShowProfile() {
    window.open(this.environment.oidcProfile, '_blank');
  }

  onChangePassword() {
    window.open(this.environment.oidcPassChange, '_blank');
  }

  onLogout() {
    this.auth.logOut();
  }

  onLanguageChange(code: LangCodes) {
    if (this.internationalizationService.setLanguage(code)) {
      // Trigger forced page reload to re-initialize state of whole app. Components might have cached translations,
      // so to refresh them a full page reload is necessary.
      window.location.reload();
    }
  }

  onOpenInNewWindow(url?: string) {
    if (url) {
      window.open(url, '_blank');
    }
  }
}
