import { registerLocaleData } from '@angular/common';
import de from '@angular/common/locales/de';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './browser-storage.service';
import { environment } from '@environments/environment';
import { Logger } from '../utils/logger';

export enum LangCodes {
  CZECH = 'cz',
  DANISH = 'da',
  GERMAN = 'de',
  ENGLISH = 'en',
  SPANISH = 'es',
  FINISH = 'fi',
  FRENCH = 'fr',
  ITALIAN = 'it',
  JAPANESE = 'ja',
  KOREAN = 'ko',
  DUTCH = 'nl',
  POLISH = 'pl',
  PORTUGUESE = 'pt',
  RUSSIAN = 'ru',
  SWEDISH = 'sv',
  TURKISH = 'tr',
  CHINESE = 'zh',
  CHINESE_TRADITIONAL = 'zhf',
}

export interface Language {
  code: LangCodes;
  label: string;
  available: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class InternationalizationService {
  private static readonly DEFAULT_LANG: LangCodes = LangCodes.ENGLISH;
  private static readonly LANG_STORAGE_PATH: string = 'app.internationalization.lang';
  private logger = new Logger(this.constructor.name);

  public readonly languages: Language[] = [
    /* { code: LangCodes.CZECH, label: 'Česky', available: true },
    { code: LangCodes.DANISH, label: 'Dansk', available: true }, */
    { code: LangCodes.GERMAN, label: 'Deutsch', available: true },
    { code: LangCodes.ENGLISH, label: 'English', available: true },
    { code: LangCodes.SPANISH, label: 'Español', available: false },
    { code: LangCodes.FRENCH, label: 'Français', available: false },
    { code: LangCodes.ITALIAN, label: 'Italiano', available: false },
    { code: LangCodes.DUTCH, label: 'Nederlands', available: false },
    { code: LangCodes.POLISH, label: 'Polski', available: false },
    { code: LangCodes.PORTUGUESE, label: 'Português', available: false },
    /* { code: LangCodes.RUSSIAN, label: 'Русский', available: true },
    { code: LangCodes.FINISH, label: 'Suomi', available: true },
    { code: LangCodes.SWEDISH, label: 'Svenska', available: true },
    { code: LangCodes.TURKISH, label: 'Türkçe', available: true },
    { code: LangCodes.CHINESE, label: '中文', available: true },
    {
      code: LangCodes.CHINESE_TRADITIONAL,
      label: '中文(台灣)',
      available: true,
    },
    { code: LangCodes.JAPANESE, label: '日本語', available: true },
    { code: LangCodes.KOREAN, label: '한국어', available: true }, */
  ];

  constructor(
    private translateService: TranslateService,
    private browserStorageService: LocalStorageService,
  ) {}

  public setup(): void {
    // Define default language
    this.translateService.setDefaultLang(InternationalizationService.DEFAULT_LANG);
    this.translateService.addLangs([InternationalizationService.DEFAULT_LANG]);

    // Define additional languages
    for (const lang of environment.additionalLanguages) {
      switch (lang) {
        case 'de': {
          registerLocaleData(de);
          break;
        }
        default:
          this.logger.error(`Locale data for '${lang}' needs to be registered`);
      }
      this.translateService.addLangs([lang]);
    }

    // First try to detect if a language has been selected manually (using menu)
    let language: string | undefined = this.browserStorageService.get(InternationalizationService.LANG_STORAGE_PATH);

    if (!language) {
      // Try autodetect the users language by browser language or use the configured default language
      language = this.tryIdentifyPreferredLang() || InternationalizationService.DEFAULT_LANG;
    }

    // Trigger loading translations
    this.translateService.use(language);
  }

  private tryIdentifyPreferredLang(): string | undefined {
    const currentBrowserLang: string | undefined = this.translateService.getBrowserLang();
    return this.translateService.getLangs().find((lang) => lang === currentBrowserLang);
  }

  public setLanguage(newLang: LangCodes): boolean {
    if (this.translateService.currentLang === newLang) {
      return false;
    }

    if (!this.translateService.getLangs().includes(newLang)) {
      return false;
    }

    this.browserStorageService.set(InternationalizationService.LANG_STORAGE_PATH, newLang);
    return true;
  }
}
