import { InjectionToken } from '@angular/core';

export const ENVIRONMENT = new InjectionToken<ArtiEnvironment>('ArtifactRepoEnvironment');

export interface ArtiEnvironment {
  dynamicSettings: boolean;
  production: boolean;
  debug: string;
  apiUrl: string;
  oidc: {
    issuer: string;
    clientId: string;
  };
  oidcPassChange: string;
  oidcProfile: string;
  groupManagement: {
    baseUrl: string;
    apiUrl: string;
    adminGroupId: string;
  };

  featureToggles: Record<string, boolean>;
  additionalLanguages: string[];
}
