<!-- Core header display -->
<div class="header">
  <img src="../../../assets/images/sick-logo-small.svg" class="logo" alt="Sick AG" />
  <div class="application_title">
    {{ applicationTitle }}
  </div>

  <app-menu icon="custom_world" iconSize="24" offset="9" title="{{ 'header.language' | translate }}">
    @for (lang of languages; track lang) {
      <app-menu-item
        [label]="lang.label"
        [disabled]="!lang.available || false"
        [icon]="lang.code === language ? 'check' : ''"
        [checked]="lang.code === language"
        (selected)="onLanguageChange(lang.code)"
      >
      </app-menu-item>
    }
  </app-menu>

  <app-menu icon="apps" iconSize="24" offset="9" title="{{ 'header.apps' | translate }}">
    @for (app of apps; track $index) {
      <app-menu-item [label]="app.label" [icon]="app.icon" (selected)="onOpenInNewWindow(app.url)"> </app-menu-item>
    }
  </app-menu>
  <app-menu icon="info" iconSize="24" offset="9" title="{{ 'header.legal-documents' | translate }}">
    @for (link of legalLinks; track $index) {
      <app-menu-item [label]="link.label" (selected)="onOpenInNewWindow(link.url)" [disabled]="link.disabled ?? false">
      </app-menu-item>
    }
  </app-menu>

  <app-menu icon="account_circle" iconSize="24" offset="9" title="{{ 'header.user' | translate }}">
    <app-menu-item [label]="'header.sickProfile' | translate" (selected)="onShowProfile()"></app-menu-item>
    <app-menu-item [label]="'header.changePassword' | translate" (selected)="onChangePassword()"></app-menu-item>
    <app-menu-item [label]="'header.logout' | translate" (selected)="onLogout()"></app-menu-item>
  </app-menu>
</div>
